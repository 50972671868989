import React from 'react'

export const AboutMock = () => {
  return (
    <div className="about-container">
      <p>
        Um supermercado imponente, com mais de 5.000m² de área construída e
        amplo estacionamento. São 22 checkouts, dentre eles 06 de
        autoatendimento e um Jade - um novo sistema de última geração que
        promete praticidade e agilidade no registro de grandes compras. Tudo
        isso com o charme de um empório.
      </p>
      <p>
        Um ambiente agradável que ressalta a confiança, o atendimento
        personalizado e produtos de excelente qualidade, pois você é único para
        nós.
      </p>
      <p>
        Um mix de exclusividade, qualidade e simpatia que farão do DOM OLÍVIO a
        sua nova casa. Podemos dizer que é um DOM que faz parte de nós e
        pensando nisso, resolvemos reunir tudo em um só lugar.
      </p>
    </div>
  )
}
